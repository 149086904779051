.App {
  background: var(--primary-black);
  font-family: var(--font-family);
  color: #81afdd;
  padding: 0.1rem;
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 50% 50%, #005a89 0%, #021138 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 50% 50%,
    #005a89 0%,
    #021138 100%
  );

  /* 255 0 0 */
  /* 153 50 204*/

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 50% 50%, #005a89 0%, #021138 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 50% 50%, #005a89 0%, #021138 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 50% 50%, #005a89 0%, #021138 100%);
}

.gradient-text {
  /* background: #25b3cf;
  background: linear-gradient(to bottom right, #25b3cf 0%, #cf2bcf 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  background: #782ba1; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #782ba1,
    #c84c4c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #782ba1,
    #c84c4c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

nav {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a {
  padding: 0 1rem;
  text-decoration: none;
  font-weight: var(--font-weight-slim);
  color: var(--font-default-color);
  cursor: pointer;
  height: 5rem;
  line-height: 5rem;
}

nav a:hover {
  color: #ca453e;
  animation: upAndDown 0.8s ease-out;
}

header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-name {
  font-size: 3rem;
  margin: var(--text-margin);
  animation: moveInLeft 1s ease-out;
}

.job-desc {
  font-size: 1.375rem;
  margin: var(--text-margin);

  animation: moveInRight 1s ease-out;
}

.skills-header {
  animation: moveIn 1s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.skills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.265rem;
  animation: moveIn 1s ease-out 0.75s;
  animation-fill-mode: backwards;
  margin-bottom: 2rem;
}

.skill {
  text-align: center;
}

.skill img {
  width: 3rem;
  height: 3rem;
}

.skill h4 {
  margin: 0.5rem 0;
  color: var(--font-default-color);
}

.projects-btn:link,
.projects-btn:visited {
  opacity: 1;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bolder;
  padding: 1rem 2rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
}

.projects-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem var(--btn-shadow);
}

.projects-btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem var(--btn-shadow);
}

.projects-btn {
  background-color: var(--primary-white);
  color: var(--primary-black);
}

.projects-btn::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.projects-btn::after {
  background-color: var(--primary-white);
}

.projects-btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.projects-btn {
  animation: moveIn 1s ease-out 0.75s;
  animation-fill-mode: backwards;
}

#about {
  text-align: center;
  margin: 1rem 4rem;
  padding: 1rem 2rem;
}

#about h2 {
  margin: 0 0 1rem;
}

#about p {
  text-align: justify;
  color: var(--font-default-color);
}

#projects {
  text-align: center;
}

.projects-container {
  margin: 0 3rem 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.projects-container a {
  text-decoration: none;
  color: inherit;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: 0.1s;
}

.projects-container a:hover {
  box-shadow: 0 0 2rem #540754a2;
}

.projects-container a:hover p {
  font-weight: bolder;
  color: var(--primary-white);
}

.projects-container img {
  width: 95%;
  height: calc(83.5%);
}

.projects-container p {
  margin-bottom: 0.5rem;
}
.github-container {
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.github-link img {
  width: 5rem;
  transition: 0.5s;
}

.github-link img:hover {
  animation: popOutLogo 1.8s;
  animation-iteration-count: infinite;
}

#social {
  margin: 0 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#social img {
  width: 5rem;
}

#social img:hover {
  animation: popOutLogo 1.8s;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 1080px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  nav {
    font-size: 0.875rem;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  #about {
    margin: 0;
  }
  .projects-container {
    margin: 0 1rem;
  }
  .skills {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .my-name {
    font-size: 2rem;
  }
}

@media screen and (max-width: 380px) {
  * {
    font-size: 0.785rem;
  }
}
