@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

:root {
  --font-family: 'Merriweather', sans-serif;
  --font-weight-slim: 300;
  --font-default-color: #bfd3f0;
  --primary-black: #161513;
  --primary-white: #f0f2f5;
  --text-margin: 1rem 0 0;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}

@keyframes popOutLogo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0.05rem);
  }
  100% {
    transform: translateY(0);
  }
}
